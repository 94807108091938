@import 'react-datepicker/dist/react-datepicker.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

main {
  display: grid;
  grid-template-columns: 224px auto;
  min-height: 100%;

  .content {
    padding: 48px 24px;
  }
}

body {
  min-width: 1366px;
  height: 100%;
  overflow-x: auto;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

:focus-visible {
  outline: 1px solid currentColor;
  outline-offset: 5px;
}

svg {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

label {
  display: block;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

sup {
  font-size: 0.6em;
  vertical-align: top;
}

input,
textarea,
button,
select {
  font: inherit;
  color: inherit;
  background-color: transparent;
  appearance: none;
  border: none;
}

summary {
  cursor: pointer;
  list-style: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }
}

iframe {
  border: none;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ul {
  li {
    list-style: none;
  }
}

h1 {
  color: var(--primary-900, #082f4c);
  font-size: 28px;
  font-weight: 400;
  line-height: 132%;
}

.select {
  min-width: 200px;

  .select__input-container {
    margin: 0;
    padding: 0;
  }

  .select__placeholder,
  .select__single-value {
    line-height: 18px;
    font-size: 14px;
    margin-left: 12px;
    color: var(--primary-900, #082f4c);
  }

  .select__placeholder {
    color: var(--secondary-500, #6c6d7d);
  }

  .select__value-container {
    padding: 0;
  }

  .select__input-container {
    margin-left: 12px;
  }

  .select__control {
    border-radius: 6px;
    border: 1px solid var(--primary-500, #67bdfb);
  }

  .select__indicator-separator {
    display: none;
  }
}

table {
  margin-top: 28px;
  width: 100%;
  border-spacing: 0;

  td {
    padding: 12px 13px;
    border-right: 1px solid var(--primary-300, #bfe4ff);
    border-bottom: 1px solid var(--primary-200, #edf7ff);
    font-size: 14px;

    > label {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 13px;
      margin: -12px -13px;
      cursor: pointer;
    }

    &:first-child {
      border-left: 1px solid var(--primary-200, #edf7ff);
    }

    &:last-child {
      border-right: 1px solid var(--primary-200, #edf7ff);
    }

    &.status div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  th {
    padding: 12px 13px;
    border: 1px solid var(--primary-200, #edf7ff);
    border-left: transparent;
    border-right: 1px solid #fff;

    > label {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 13px;
      margin: -12px -13px;
      cursor: pointer;
    }

    &:last-child {
      border-right: 1px solid var(--primary-200, #edf7ff);
    }

    background: var(--primary-300, #bfe4ff);
    text-align: left;
    font-size: 12px;
    font-weight: 400;
  }
}

label {
  > span {
    margin-bottom: 2px;
    color: var(--secondary-500, #6c6d7d);
    font-size: 12px;
    line-height: 148%;
  }
}

.content:has(.Loader) {
  > *:not(.Loader) {
    filter: blur(4px);
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.content:has(.Popup) {
  > *:not(.Popup) {
    filter: blur(4px);
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.authorize-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  max-height: 600px;
  padding: 48px;
  background: var(--secondary-100, #fff);
  box-shadow:
    0px 4px 10px 0px rgba(126, 138, 146, 0.12),
    0px 4px 16px 0px rgba(126, 138, 146, 0.3);

  button {
    margin: 0 auto;
  }

  label {
    display: grid;
    gap: 2px;
    margin-bottom: 24px;

    color: var(--secondary-500, #6c6d7d);
    font-size: 12px;
    font-weight: 400;
    line-height: 148%;
  }

  .error {
    color: var(--error-500, #cb2f2f);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 148%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
